


import Vue from "vue";
import Vuex from "vuex";
import router from "./router";
// import axios from "axios";

Vue.use(Vuex);
export default new Vuex.Store({

  state: {
    isLoggedIn: !!localStorage.getItem("token"),
    userType: localStorage.getItem("userType"),
    userData: {},
    email: "",
    menus: [],
    initial: "",
    appLoading: false,
    USDD:""

  },
  mutations: {

    loginUser(state, payload) {
      localStorage.setItem("token", payload);
      state.isLoggedIn = true;

      if (state.isLoggedIn == true) {
        router.push("/admin")
      }

    },
    logoutUser(state) {
      state.userType = null;
      state.isLoggedIn = false;
      state.userData = {};
      state.initial = "";
      localStorage.removeItem("userType");
      localStorage.removeItem("token");
      router.push("/login");

    },

    userData(state, payload) {
      state.userData = payload;
      state.email = state.userData.phone;
    },
    userType(state, payload) {
      localStorage.setItem("userType", payload);
      state.userType = payload;
    },
    appLoading(state, payload) {
      state.appLoading = payload;
    },
    USD( state, payload)
    {
    state.USDD =payload
    },


    sessionOut(state) {
      localStorage.removeItem("token");
      state.isLoggedIn = false;
    },

  },
});
