<template>
  <div>
    <v-app-bar height="80px" app color="#002266" class="rdx">
      <v-layout wrap>
        <v-flex xs2 sm1 class="hidden-md-and-up" align-self-center>
          <v-app-bar-nav-icon
            style="color: white"
            @click="nav = true"
          ></v-app-bar-nav-icon>
          <v-navigation-drawer height="450px" absolute v-model="nav">
            <v-layout class="menufont1" wrap justify-center pa-3>
              <v-flex xs12>
                <v-list dense nav v-for="(item,i) in routes" :key="i">
                  <router-link :to="item.link" class="routerlink">
                    <v-list-item link>
                      <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title class="bold">{{
                          item.name
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </router-link>
                </v-list>
              </v-flex>
            </v-layout>
          </v-navigation-drawer>
        </v-flex>
        <v-flex xs3 lg1 class="hidden-sm-and-down">
          <span
            ><v-img src="../assets/images/asw.png" contain height="60px"></v-img
          ></span>
        </v-flex>
        <v-flex align-self-center>
          <span style="font-family: syneb; font-size: 25px; color: #ffffff"
            >Anti-Snare</span
          >
        </v-flex>
        <v-spacer> </v-spacer>
        <v-flex xs2 align-self-center>
          <AccountMenu
            style="cursor: pointer"
            v-if="$route.path != '/privacyPolicy'"
          />
        </v-flex>
      </v-layout>
    </v-app-bar>
  </div>
</template>
<script>
import AccountMenu from "../common/accountMenu";
export default {
  components: {
    AccountMenu,
  },
  data() {
    return {
      drawer: false,
      nav: false,
      routes: [
        {
          icon: "mdi-view-dashboard",
          name: "Dash board",
          link: "/admin",
        },
        {
          icon: "mdi-account",
          name: "User List",
          link: "/userlist",
        },
        {
          name: "Snare Types",
          link: "/manageSnare",
          icon: "mdi-paw",
        },
        // {
        //   name: "User List",
        //   link: "/userlist",
        //   icon: "mdi-account",
        // },
        {
          name: "Reportings",
          link: "/reportings",
          icon: "mdi-page-layout-sidebar-left",
        },
        {
          name: "Trainings",
          link: "/trainings",
          icon: "mdi-run",
        },
        {
          name: "Animal Rescue",
          link: "/animalrescue",
          icon: "mdi-unicorn",
        },

        {
          name: "Privacy Policy",
          link: "/privacy",
          icon: "mdi-note",
        },
      ],
    };
  },
};
</script>
<style scoped>
.rdx {
  /* background-color: rgba(37, 34, 36, 0.58) !important; */
  background-color: #313563 !important;
}
.routerlink {
  text-decoration: none;
  font-family: syne;
}
</style>