import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from 'axios'


import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

import ServerError from './common/500'
Vue.component('ServerError', ServerError)
import VueElementLoading from 'vue-element-loading'
Vue.component('VueElementLoading', VueElementLoading)
import { VueEditor } from "vue2-editor";
Vue.component('VueEditor', VueEditor)
Vue.config.productionTip = false

// axios.defaults.baseURL = 'http://192.168.55.167:5141';
// Vue.prototype.baseURL = 'http://192.168.55.167:5141';
// Vue.prototype.mediaURLOld = 'http://192.168.55.167:5141'; 
// Vue.prototype.mediaURL = 'http://192.168.55.167:5141/file/get/';

// axios.defaults.baseURL = 'http://3.108.185.79';
// Vue.prototype.baseURL = 'http://3.108.185.79';
// Vue.prototype.mediaURLOld = 'http://3.108.185.79';
// Vue.prototype.mediaURL = 'http://3.108.185.79/file/get/';

axios.defaults.baseURL = 'http://antisnare.leopardtechlabs.in';
Vue.prototype.baseURL = 'http://antisnare.leopardtechlabs.in';
Vue.prototype.mediaURLOld = 'http://antisnare.leopardtechlabs.in';
Vue.prototype.mediaURL = 'http://antisnare.leopardtechlabs.in/file/get/';

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
