<template>
  <v-app >
    <v-main>
      <Header v-if="$route.name!= 'login'"/>
      <!-- <Headerxs/> -->
     
     <router-view  >
     </router-view>
    
      <Footer v-if="$route.name!= 'login'"/>
    </v-main>
  </v-app>
</template>
<script>
// import HelloWorld from './components/HelloWorld';
import "./assets/style/fonts.css";
import "./assets/style/style.css";
import Header from './common/appHeader'
// import Headerxs from './common/appHeaderxs'

import Footer from './common/footer'
import axios from 'axios'
import store from './store'


export default {
  name: 'App',

  components: {
    // HelloWorld,
    Header,
    Footer,
    // Headerxs

  },
  
  beforeMount() {
    if (typeof localStorage.getItem("token") == "string") {
      // this.$store.commit("appLoading", true);
      axios({
        method: "get",
        url: "/user/me",
        headers: {
          token: localStorage.getItem ("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            store.commit("appLoading", false);
            store.commit("userData", response.data.data);
            store.commit("userType", response.data.data.role);
          
          }
          // else
          // {
          //   this.$store.commit("appLoading", false);

          // }
        })
        .catch((err) => {
          // this.$store.commit("appLoading", false);
          console.log(err);
        });
    }
  },
  data: () => ({
    //
  }),

};
</script>
<style >
#app {
 -webkit-font-smoothing:antialiased;
 -moz-osx-font-smoothing:grayscale;
 margin-left: 2px;
}

</style>
